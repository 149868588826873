import {
  getLoginMsgCode,
  setVerifyCode,
  userRegister,
  userLogin,
  resetPassword,
  outLogin,
  getPublicKey,
} from '@/api/login'
// import store from "@/store";

const login = {
  state () {
    return {
      token: '',
      userInfo: {},
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
  },

  actions: {
    // 获取手机登录验证码
    UserLogin ({ commit }, postData) {
      return new Promise((resolve, reject) => {
        userLogin(postData)
          .then((response) => {
            commit('SET_USERINFO', response.result)
            commit('SET_TOKEN', response.result.token)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取手机登录验证码
    LoginMsgCode ({ commit }, tel) {
      return new Promise((resolve, reject) => {
        getLoginMsgCode(tel)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LoginMsgCodeNext ({ commit }, getData) {
      return new Promise((resolve, reject) => {
        setVerifyCode(getData.tel, getData.code)
          .then((response) => {
            // console.log('setVerifyCode: ', response)
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    UserRegister ({ commit }, postData) {
      // console.log('UserRegister: ', postData)
      return new Promise((resolve, reject) => {
        userRegister(postData)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    ResetPassword ({ commit }, postData) {
      return new Promise((resolve, reject) => {
        resetPassword(postData)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    OutLogin ({ commit }) {
      return new Promise((resolve, reject) => {
        outLogin()
          .then((response) => {
            commit('SET_USERINFO', '')
            commit('SET_TOKEN', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		 // 获取公钥
		 PublicKey ({ commit }) {
			return new Promise((resolve, reject) => {
				getPublicKey()
					.then((response) => {
						commit('', '')
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
  },

}

export default login
