/**
 * @categoryAPI 分类页API
 */
import request from '@/utils/request'

/**
 * 获取指定一级分类数据
 * @param id 一级分类ID
 * @returns {*}
 */
export function getTopCategoryById (id) {
  return request.get('/category', { id })
}

/**
 * 根据二级分类ID获取筛选条件
 * @param id 二级分类ID
 * @returns {*}
 */
export function getSubCategoryFilterById (id) {
  return request.get('/category/sub/filter', { id })
}

/**
 * 获取商品列表
 * @param params 分类id、筛选条件、排序条件、分页信息
 * @returns {*}
 */
export function getGoodsReq (params) {
  return request.post('/category/goods', params)
}

/**
 * 获取 商品列表筛选栏
 * @param
 * @returns {*}
 */
export function getProductFilter (params) {
  return request.post('/api/product/getProductFilter', params)
}
export function getProductFiltertWithToken (params) {
  return request.post('/api/product/getProductFilter', params, { withToken: true })
}

/**
 * 获取 商品列表
 * @param
 * @returns {*}
 */
export function getProductList (params) {
  return request.post('/api/product/getProductList', params)
}
export function getProductListWithToken (params) {
  return request.post('/api/product/getProductList', params, {
    withToken: true,
  })
}

/**
 * 查询商品名称列表
 * @param id 商品ID
 * @returns {*}
 */
export function getProductNames (params) {
  return request.post('/api/product/getProductNames', params)
}

/**
 * 条件查询规格列表
 * @param id 商品ID
 * @returns {*}
 */
export function getFormates (params) {
  return request.post('/api/product/getFormates', params)
}

/**
 * 条件查询规格列表
 * @param id 商品ID
 * @returns {*}
 */
export function getMaterials (params) {
  return request.post('/api/product/getMaterials', params)
}

/**
 * 获取商品单位
 * @param id 商品ID
 * @returns {*}
 */
export function getUnits () {
  return request.get('/api/product/getUnits')
}
/**
 * 发布商品
 * @param id 商品ID
 * @returns {*}
 */
export function addProduct (params) {
  return request.post('/api/product/addProduct', params, { withToken: true })
}

/**
 * 购买商品 提交订单
 * @param
 * @returns {*}
 */
 export function submitOrder (params) {
  return request.post('/api/payment/submitOrder', params, { withToken: true })
}
/**
 * 商品 上下架
 * @param
 * @returns {*}
 */
 export function updateProductStatus (id, status) {
  return request.get('/api/product/updateProductStatus', { id, status }, { withToken: true })
}
