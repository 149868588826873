import { getProductDetail } from '@/api/goods'
// import store from "@/store";

const good = {
  state () {
    return {
      // token: '',
      merchantStore: {},
    }
  },

  mutations: {
    SET_MERCHANTSTORE: (state, merchantStore) => {
      state.merchantStore = merchantStore
    },
  },

  actions: {
    //  ID获取商品详情信息
    ProductDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        getProductDetail(id)
          .then((response) => {
            commit('SET_MERCHANTSTORE', response.result.merchantStore)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

  },
}

export default good
