<template>
<div>
  <div class="section-title">
    <span class="icon"></span>
    <span class="text">
      <slot name="title"/>
    </span>
  </div>
  <div class="section-main">
    <slot/>
  </div>
</div>

</template>
<script>
export default {
  name: 'PageSection'
}
</script>
<style lang="less">
  .section-title {
    height: 20px;
    line-height: 1;
    margin-bottom: 10px;
    .icon {
      display: inline-block;
      height: 100%;
      width: 5px;
      background-color: @xtxColor;
      margin-right: 10px;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .section-main {
    padding: 20px 0;
  }
</style>
