/**
 * @homeAPI 首页API
 */
import request from '@/utils/request'

// 获取首页分类数据
export function getCategoriesAPI () {
  return request.get('/home/category/head')
}

/**
 * 获取热门品牌
 * @param limit
 * @returns {*}
 */
export function getBrands (limit = 10) {
  return request.get('/home/brand', { limit })
}

/**
 * 获取轮播数据
 * @param distributionSite 广告区域投放位置（1为首页，2为分类商品页） 默认是1
 * @returns {*}
 */
export function getBanners (distributionSite = 1) {
  return request.get('/home/banner', { distributionSite })
}

/**
 * 获取新鲜好物
 * @param limit 商品数量
 * @returns {*}
 */
export function getNewGoods (limit = 4) {
  return request.get('/home/new', { limit })
}

/**
 * 获取人气推荐
 * @returns {*}
 */
export function getHomeHot () {
  return request.get('/home/hot')
}

/**
 * 获取产品区块
 * @returns {*}
 */
export function getProducts () {
  return request.get('/home/goods')
}

/**
 * 获取最新专题
 * @param limit 限制数据数量
 * @returns {*}
 */
export function getSpecial (limit) {
  return request.get('/home/special', { limit })
}

// 获取首页热门资源 list
export function getHotProduct () {
  return request.get('/api/product/getHotProduct')
}
// 获取首页挂牌资源 list
export function getAvailabelProduct () {
  return request.get('/api/product/getAvailabelProduct')
}
/**
 * 获取轮播数据
 * @param
 * @returns {*}
 */
export function getAdpictureList () {
  return request.get('/api/adPicture/getAdpictureList')
}
/**
 * 获取平台公告
 * @param
 * @returns {*}
 */
export function getNoticeList (params) {
  return request.post('/api/notice/getNoticeList', params)
}
/**
 * 获取平台公告 详情
 * @param
 * @returns {*}
 */
 export function getNoticeDetails (id) {
  return request.get(`/api/notice/getNoticeDetails/${id}`)
}
/**
 * 获取店铺详情
 * @param
 * @returns {*}
 */
export function getStoreDetail (storeId) {
  return request.get('/api/merchant/getStoreDetail', { storeId })
}

/**
 * 获取商品分类tree
 * @param
 * @returns {*}
 */
 export function getCategoryTree () {
  return request.get('/api/productCategory/getCategoryTree')
}

/**
 * 获取 交易专区采购统计
 * @param
 * @returns {*}
 */
 export function getPurchaseStatistics (params) {
  return request.post('/api/product/getPurchaseStatistics', params)
}

/**
 * 获取 交易专区供应统计
 * @param
 * @returns {*}
 */
 export function getSupplyStatistics (params) {
  return request.post('/api/product/getSupplyStatistics', params)
}
