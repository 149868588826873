<template>
	<div class="steps-item" :class="currentStatus">

		<span class="step-number">{{ stepNumber }}</span>
		<span class="step-text"> {{ stepText }}</span>
		<img
			v-if="showArrow"
			class="step-arrow"
			:src="require('/static/icons/right.png')"
			alt=""
		/>
	</div>
</template>
<script>
// 生成随机字符串
function random (len = 32) {
    const $chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    const maxPos = $chars.length
    let str = ''
    for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return str
}

export default {
	props: {
		stepNumber: {
			type: Number,
			default: 1,
		},
		stepText: {
			type: String,
			default: '',
		},
		showArrow: {
			type: Boolean,
			default: true,
		},
		nowStep: {
			type: Number,
			default: 1,
		},
	},
	inject: ['StepsInstance'],
	name: 'AppStepsItem',
	data () {
		return {
            id: random(6)
        }
	},
	mounted () {

	},
	computed: {
		currentStatus () {
            let status = ''
			const StepsInstance = this.StepsInstance
			const current = StepsInstance.current
			const index = StepsInstance.steps.findIndex((item) => item.id === this.id)
			if (index === current) {
				status = 'process'
			} else if (index < current) {
				status = 'finish'
			} else {
				status = 'wait'
			}
            return status
        }
	},
    beforeMount () {
        this.StepsInstance.addStep(this.id, this)
    },
}
</script>
<style lang="less">
.steps-item {
	flex: 1;
	display: flex;
	justify-content: center;
	font-size: 14px;
	position: relative;

	.step-number {
		display: inline-block;
		width: 20px;
		height: 20px;
		border: 1px solid #585859;
		border-radius: 50%;
		text-align: center;
		line-height: 20px;
		margin-right: 10px;
	}

	.step-text {
		color: #444445;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
	}

	.step-arrow {
		position: absolute;
		right: 0;
	}
}
.wait {
    span {
        color: #b4b4b5 !important;
    }
    .step-number {
        border-color: #b4b4b5;
    }
}
.finish {
    span {
        color: #d12b23 !important;
    }
    .step-number {
        border-color: #d12b23;
    }
}
.process {
    span {
        color: inherit;
    }
    .step-number {
        border-color: inherit;
    }
}
</style>
