import { createRouter, createWebHashHistory } from 'vue-router'
import authGuard from './authGuard'
// import HomePage from "../views/home/HomePage.vue";

const HomePage = () => import('@/views/home/HomePage')
const TopCategoryPage = () => import('@/views/category/TopCategoryPage')
const SubCategoryPage = () => import('@/views/category/SubCategoryPage')
const GoodsDetailPage = () => import('@/views/goods/GoodsDetailPage')
const LoginPage = () => import('@/views/login/LoginPage')
const LoginCallbackPage = () =>
  import('@/views/login/components/LoginCallbackPage')
const CartPage = () => import('@/views/cart/CartPage')
// const CheckoutPage = () => import('@/views/pay/CheckoutPage')
// const PayPage = () => import('@/views/pay/PayPage')
// const PayResultPage = () => import("@/views/pay/PayResultPage");
const MemberHomePage = () => import('@/views/member/home/MemberHomePage')
const ShopPage = () => import('@/views/member/shop/ShopPage')
const NominalHomePage = () => import('@/views/member/nominal/NominalHomePage')
const SubmitOrder = () => import('@/views/checkout/SubmitOrder')
const SubmitSuc = () => import('@/views/checkout/SubmitSuc')
const Checkout = () => import('@/views/checkout/Checkout')
const MerchandisePage = () =>
  import('@/views/member/merchandise/MerchandisePage')
const MerchandiseIndex = () => import('@/views/member/merchandise/Index')
const BuyingIndex = () => import('@/views/member/order/buying/Index')
const BuyingPage = () => import('@/views/member/order/buying/BuyingPage')
const MemberIndex = () => import('@/views/member/Index')
const OrderPage = () => import('@/views/member/order/OrderPage')
const OrderDetailPage = () => import('@/views/member/order/OrderDetailPage')
const OrderView = () => import('@/views/member/order/OrderView')
const PurchaseOrderView = () =>
  import('@/views/member/order/purchase/PurchaseOrderView')
const PurchaseOrderPage = () =>
  import('@/views/member/order/purchase/PurchaseOrderPage')
const PurchaseOrderDetailPage = () =>
  import('@/views/member/order/purchase/PurchaseOrderDetailPage')
const QuotationOrderView = () =>
  import('@/views/member/order/quotation/QuotationOrderView')
const QuotationOrderPage = () =>
  import('@/views/member/order/quotation/QuotationOrderPage')
const QuotationOrderDetailPage = () =>
  import('@/views/member/order/quotation/QuotationOrderDetailPage')
const BuyingOrderDetailPage = () =>
  import('@/views/member/order/buying/BuyingOrderDetailPage')

// 定义路由列表
const routes = [
  { path: '/', component: HomePage },
  { path: '/category/:id', component: TopCategoryPage },
  { path: '/category/sub', component: SubCategoryPage },
  { path: '/category/sub/:id', component: SubCategoryPage },
  { path: '/goods/:id', component: GoodsDetailPage },
  { path: '/login', component: LoginPage },
  { path: '/login/callback', component: LoginCallbackPage },
  { path: '/register', component: () => import('@/views/login/Register') },
	{ path: '/forget', component: () => import('@/views/login/Forget') },
  // { path: '/qlcheckout/paypage', component: PayPage }, // 确认订单
  { path: '/qlcheckout/submit', component: SubmitOrder }, // 确认订单
  { path: '/qlcheckout/submitsuc', component: SubmitSuc }, // 订单提交成功
  { path: '/qlcheckout/checkout', component: Checkout }, // 收银台
  {
    path: '/user',
    component: MemberIndex,
    children: [
      // 个人中心
      { path: 'home', component: MemberHomePage },

      // 个人中心-nominalom
      {
        path: 'nominalominalhome',
        component: NominalHomePage,
        meta: { page: 'nominalominal' },
      },
      // 商家认证
      {
        path: 'authentication', component: () => import('@/views/member/certification/EnterpriseCertificationInfo'),
      },

      // 我的商店
      { path: 'shop', component: ShopPage },

      // 商品中心
      {
        path: 'merchandise',
        component: MerchandiseIndex,
        children: [
          {
            path: '',
            component: MerchandisePage,
            meta: {
              page: 'page',
            },
          },
          {
            path: 'publish',
            component: () =>
              import('@/views/member/merchandise/MerchandisePublish'),
            meta: {
              page: 'publish',
            },
          },
        ],
      },
      // 订单中心
      {
        path: 'order',
        component: OrderView,
        children: [
          { path: '', component: OrderPage },
          {
            path: ':id',
            component: OrderDetailPage,
            children: [
              {
                path: '',
                component: MerchandisePage,
                meta: {
                  page: 'page',
                },
              },
            ],
          },
        ],
      },
      // 我的采购单
      {
        path: 'purchase',
        component: PurchaseOrderView,
        children: [
          {
            path: '',
            component: PurchaseOrderPage,
            meta: {
              page: 'page',
            },
          },
          {
            path: ':id',
            component: PurchaseOrderDetailPage,
          },
        ],
      },
      // 我的报价单
      {
        path: 'quotation',
        component: QuotationOrderView,
        children: [
          {
            path: '',
            component: QuotationOrderPage,
            meta: {
              page: 'page',
            },
          },
          {
            path: ':id',
            component: QuotationOrderDetailPage,
          },
        ],
      },
      // 我的求购单
      {
        path: 'buying',
        component: BuyingIndex,
        children: [
          {
            path: '',
            component: BuyingPage,
            meta: {
              page: 'page',
            },
          },
          {
            path: ':id',
            component: BuyingOrderDetailPage,
          },
        ],
      },
      // 账户余额
      {
        path: 'account',
        component: () => import('@/views/member/account-money/Index'),
        children: [
          {
            path: 'balance',
            component: () => import('@/views/member/account-money/balance/Index'),
            children: [
              {
                path: '',
                component: () =>
                  import('@/views/member/account-money/balance/AccountBalance'),
              },
              {
                path: 'open-account',
                component: () => import('@/views/member/account-money/balance/OpenAcount'),
              },
              // {
              //   path: 'withdraw',
              //   component: () =>
              //     import('@/views/member/account-money/withdraw/Withdraw'),
              // },
            ],
          },
          {
            path: 'frozen-money',
            component: () => import('@/views/member/account-money/frozen-money/Index'),
            meta: {
              page: 'frozen-money',
            },
            children: [{
              path: '',
              component: () => import('@/views/member/account-money/frozen-money/FrozenMoney'),
            }]
          },
          {
            path: 'withdraw-list',
            component: () =>
              import('@/views/member/account-money/withdraw/Index'),
              children: [{
                path: '',
                component: () => import('@/views/member/account-money/withdraw/WithdrawList'),
              }]
          },
        ],
      },
      {
        path: 'account-bind',
        component: () => import('@/views/member/account-bind/Index'),
        children: [
          {
            path: 'forms',
            component: () => import('@/views/member/account-bind/AccountBind'),
          },
          {
            path: 'info',
            component: () => import('@/views/member/account-bind/AcountBindInfo'),
          },
        ],
      },
    ],
  },
  {
    path: '/company/:id',
    component: () => import('@/views/category/CompanyPage'),
  },
  { path: '/message', component: () => import('@/views/message/Index') },
  {
    path: '/message/detail',
    component: () => import('@/views/message/MessageDetail'),
  },
  { path: '/cart', component: CartPage },
  // { path: '/checkout/order', component: CheckoutPage },
  // { path: '/checkout/pay', component: PayPage },

  {
    path: '/publish',
    component: () => import('@/views/publish/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/publish/PublishNeeds'),
        meta: {
          page: 'publish',
        },
      },
      {
        path: 'needslist',
        component: () => import('@/views/publish/NeedsList'),
        meta: {
          page: 'needslist',
        },
      },
      {
        name: 'needslist_detail',
        path: 'needslist/:id',
        component: () => import('@/views/publish/NeedsListDetail'),
        meta: {
          page: 'needsdetail',
        },
      },
    ],
  },
  { path: '/identify', component: () => import('@/views/member/certification/EnterpriseCertification') },
  // 交易专区
  { path: '/trading-area', component: () => import('@/views/home/HomeTradingMore') },
  // { path: "/member/order/:id", component: OrderDetailPage },
  /* 404 */
  {
    path: '/notfound',
    component: () => import('@/views/NotFound'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/notfound',
  },
]

// 创建并生成路由
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 每次跳转返回顶部
  scrollBehavior () {
    return { top: 0 }
  },
  // 定义【模糊匹配】和【精确匹配】激活类名
  linkActiveClass: 'fuzzy-active',
  linkExactActiveClass: 'exact-active',
})

// 路由守卫——登录拦截
router.beforeEach(authGuard)

export default router
