<template>
	<div class="steps-container">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AppSteps',
	provide () {
		return {
			StepsInstance: this,
		}
	},
	props: {
		current: {
			type: Number,
			default: 0,
		},
	},
	data () {
		return {
			steps: [],
		}
	},
  computed: {
    children () {
      return this.steps.map(item => item.step)
    }
  },
	created () {},
  methods: {
    addStep (id, step) {
      this.steps.push({ id, step })
    },
  }
}
</script>

<style lang="less">
.steps-container {
	background-color: #f5f7fa;
	height: 50px;
	display: flex;
	align-items: center;
}
</style>
