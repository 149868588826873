import {
  getListPaymentOrder,
  getPurchasePaymentOrder,
  getPaymentPaymentOrder,
  getListQuotation,
  getQuotationInfo,
  listPreOrderGoods,
  getPreOrderGoodsInfo,
  getListPurchaseOrder,
  removePaymenteOrder,
  removePurchaseOrder,
  removeQuotationOrder,
  delPaymenteOrder,
  delPurchaseOrder,
  // removePreOrderGoods,
  paymentUpdatePayStatus,
  firmPaymentOrder,
  delPreOrderGoods,
	cancelPreOrderGoods,
  purchaseSignContract,
  purchaseUploadWarehouseOrder,
  purchaseUploadPaymentBill,
  paymentFirmPaymentOrder,
  paymentConfirmContrac,
  paymentPayImmediately,
  paymentSubmitWithGuaranteeOrder,
  paymentConfirmGoods,
  purchaseUpdatePreOrderGoods,
  quotationUpdateQuotation,
  purchaseConfirmQuotation,
  paymentRequestPreOrderDepositPay,
} from '@/api/order'

// import store from "@/store";

const order = {
  state () {
    return {
      // token: '',
      // userInfo: {},
    }
  },

  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token
    // },
  },

  actions: {
    //  获取 提单列表
    ListPaymentOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getListPaymentOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  取消 提单
    RemovePaymenteOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        removePaymenteOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    //  获取 订单列表
    ListPurchaseOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getListPurchaseOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  取消 订单
    RemovePurchaseOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        removePurchaseOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  删除 订单
    DelPurchaseOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        delPurchaseOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  删除 提单
    DelPaymenteOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        delPaymenteOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  删除 我的求购单
    DelPreOrderGoods ({ commit }, params) {
      return new Promise((resolve, reject) => {
        delPreOrderGoods(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		//  取消 我的求购单
    CancelPreOrderGoods ({ commit }, params) {
      return new Promise((resolve, reject) => {
        cancelPreOrderGoods(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },


    //  修改 我的求购单
    PurchaseUpdatePreOrderGoods ({ commit }, params) {
      return new Promise((resolve, reject) => {
        purchaseUpdatePreOrderGoods(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  修改 我的报价单
    QuotationUpdateQuotation ({ commit }, params) {
      return new Promise((resolve, reject) => {
        quotationUpdateQuotation(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    //  取消 我的报价单
    RemoveQuotationOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        removeQuotationOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    PreOrderGoodsListWithToken ({ commit }, params) {
      return new Promise((resolve, reject) => {
        listPreOrderGoods(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 订单详情
    PurchasePaymentOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getPurchasePaymentOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 提单详情
    PaymentPaymentOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getPaymentPaymentOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    ListQuotation ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getListQuotation(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    QuotationInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getQuotationInfo(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    PreOrderGoodsInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getPreOrderGoodsInfo(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 确认 我的订单
    FirmPaymentOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        firmPaymentOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的订单 上传合同
    PurchaseSignContract ({ commit }, params) {
      return new Promise((resolve, reject) => {
        purchaseSignContract(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的订单 上传仓单
    PurchaseUploadWarehouseOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        purchaseUploadWarehouseOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的订单 上传发票
    PurchaseUploadPaymentBill ({ commit }, params) {
      return new Promise((resolve, reject) => {
        purchaseUploadPaymentBill(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 确认 我的提单
    PaymentFirmPaymentOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentFirmPaymentOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的提单 付保证金
    PaymentRequestPreOrderDepositPay ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentRequestPreOrderDepositPay(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的提单 确认合同
    PaymentConfirmContrac ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentConfirmContrac(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的提单 立即支付
    PaymentPayImmediately ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentPayImmediately(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 我的提单 支付回调
    PaymentUpdatePayStatus ({ commit }, orderNo) {
      return new Promise((resolve, reject) => {
        paymentUpdatePayStatus(orderNo)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    PaymentSubmitWithGuaranteeOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentSubmitWithGuaranteeOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 我的提单 确认收货
    PaymentConfirmGoods ({ commit }, params) {
      return new Promise((resolve, reject) => {
        paymentConfirmGoods(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 我的求购单 选择该报价
    PurchaseConfirmQuotation ({ commit }, params) {
      return new Promise((resolve, reject) => {
        purchaseConfirmQuotation(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default order
