/**
 * @userAPI 用户API
 */
 import request from '@/utils/request'

 /**
  * 开户
  */
 export function openAcount (params) {
    return request.post('/api/sccbaBank/openAccount', params, { withToken: true })
 }


/**
 * 账户余额
 */
 export function getBalance (params) {
    return request.get('/api/sccbaBank/getBalance', params, { withToken: true })
 }

 /**
 * 交易明细
 */
  export function getDetail (params) {
    return request.get('/api/sccbaBank/getDetail', params, { withToken: true })
 }

 /**
 * 获取省份城市
 */
  export function getProvinceAndCityOptions () {
    return request.get('/api/sccbaBank/addressList')
 }

 /**
 * 获取验证url
 */
  export function getWithdrawUrl () {
   return request.post('/api/sccbaBank/withdraw', {}, { withToken: true })
}

 /**
 * 查询开户行
 */
  export function getOpenAccoutBank (params) {
   return request.get('/api/sccbaBank/searchBank', params, { withToken: true })
}

/**
 * 银行卡绑定
 */
 export function bindBankCard (params) {
 return request.post('/api/sccbaBank/bindBankCard', params, { withToken: true })
}

 /**
 * 获取结算账户列表
 */
  export function getBindBankCardList (params) {
   return request.get('/api/sccbaBank/bindBankCardList', params, { withToken: true })
}

 /**
 * 查看银行回执单地址
 */
  export function getBankBackOrder (params) {
		return request.get('/api/sccbaBank/getBankBackOrder', params, { withToken: true })
 }
