/*
  ql web 公共组件
*/
const importFn = require.context('./', true, /\.vue$/)
const keys = importFn.keys()
const component = {
    install (app) {
      keys.forEach((item) => {
        const component = importFn(item).default
        app.component(component.name, component)
      })
    },
  }

export default component
