<template>
  <div class="xtx-number-box">
    <div class="label">
      {{ label }}
    </div>
    <div class="number-box">
      <!-- <a href="javascript:" @click="onNumberChange(-1)">-</a> -->
      <!-- <input
        :v-model="number"
        type="text"
				:value="number"
        @input="inputChange"
      /> -->

      <!-- <Input
        v-model="number"
        type="number"
        :border="false"
        @on-keyup="inputChange(number)"
        @on-change="onChange()"
      ></Input> -->
      <!-- <a href="javascript:" @click="onNumberChange(1)">+</a> -->
      <InputNumber
        v-model="number"
        controls-outside
        :formatter="(value) => `${parseFloat(Math.floor(value * 100) / 100)}`"
        :max="max"
        :min="1"
      />
    </div>
  </div>
</template>
<script>
// import { useVModel } from '@vueuse/core'
import VueEvent from '@/model/event'
export default {
  name: 'XtxNumberBox',
  props: {
    label: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      number: 1,
    }
  },
  // computed: {
  //    number () {
  //     return {}
  // 		}
  // },
  created () {
    this.init()
  },
  methods: {
    init () {},
    onChange () {
      this.number = this.number
        .toString()
        .replace(/[^\d.]/g, '')
        .replace(/^\./g, '')
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^()*(\d+)\.(\.?\d\d).*$/, '$1$2.$3')
    },
    inputChange () {
      // this.number = this.number.replace(
      //   /^(-)*(\d+)\.(\d\d).*$/,
      //   '$1$2.$3',
      // )
      console.log('aaa')
      this.number = this.number
        .replace(/[^\d.]/g, '')
        .replace(/^\./g, '')
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^()*(\d+)\.(\.?\d\d).*$/, '$1$2.$3')
    },
    onNumberChange (step) {
      const nextNumber = Number(this.number) + Number(step)
      if (nextNumber < 1) this.number = 1
      else if (Number(nextNumber) > Number(this.max)) this.number = this.max
      else this.number = nextNumber
    },
    // inputChange (e) {
    //   // this.number = e.target.value.replace(/\D/g, '').replace(/^0{1,}/g, '')
    //   this.number = e.target.value
    //     .replace(/[^\d.]/g, '')
    //     .replace(/^\./g, '')
    //     .replace(/\.{2,}/g, '.')
    //     .replace('.', '$#$')
    //     .replace(/\./g, '')
    //     .replace('$#$', '.')
    //     .replace(/^()*(\d+)\.(\.?\d\d\d\d).*$/, '$1$2.$3')

    //   console.log(this.number)
    // },
  },
  watch: {
    number (val) {
      // console.log(val)
      const nextNumber = val
      if (nextNumber < 1) this.number = 1
      else if (Number(nextNumber) > Number(this.max)) this.number = this.max
      else this.number = nextNumber

      VueEvent.emit('buyMitt', this.number.toFixed(2))
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  // setup (props, { emit }) {
  //   // 实现双向数据绑定
  //   const number = useVModel(props, 'modelValue', emit)

  //   // 改变商品数量
  //   const onNumberChange = (step) => {
  //     const nextNumber = number.value + step
  //     if (nextNumber < 1) number.value = 1
  //     else if (nextNumber > props.max) number.value = props.max
  //     else number.value = nextNumber
  //   }

  //   return { number, onNumberChange }
  // },
}
</script>
<style scoped lang="less">
.xtx-number-box {
  display: flex;
  align-items: center;
  .label {
    width: 60px;
    color: #999;
    padding-left: 5px;
  }
  .number-box {
    width: 180px;
    height: 33px;
    // border: 1px solid #e4e4e4;
    display: flex;
    > a {
      width: 29px;
      line-height: 28px;
      text-align: center;
      background: #f8f8f8;
      font-size: 16px;
      color: #666;
      &:first-of-type {
        border-right: 1px solid #e4e4e4;
      }
      &:last-of-type {
        border-left: 1px solid #e4e4e4;
      }
    }
    > input {
      width: 120px;
      padding: 0 5px;
      text-align: center;
      color: #666;
    }
  }
}
</style>
