import request from '@/utils/request'

/**
 * 获取手机登录验证码
 * @param mobile
 * @returns {*}
 */
export function getLoginMsgCode (tel) {
  return request.get('/api/pcMember/getVerifyCode', {
    tel,
  })
}

/**
 * 注册-点击下一步
 * @param mobile
 * @returns {*}
 */
export function setVerifyCode (tel, code) {
  return request.get('/api/pcMember/setVerifyCode', {
    tel,
    code,
  })
}

/**
 * 注册
 * @param mobile
 * @returns {*}
 */

export function userRegister (params) {
  return request.post('/api/pcMember/register', params)
}
/**
 * 登录
 * @param mobile
 * @returns {*}
 */
export function userLogin (params) {
  return request.post('/api/pcMember/login', params)
}

/**
 * 重置密码
 * @param mobile
 * @returns {*}
 */
 export function resetPassword (params) {
  return request.post('/api/pcMember/resetPassword', params)
}

/**
 * 退出登录
 * @param mobile
 * @returns {*}
 */
 export function outLogin () {
  return request.get('/api/pcMember/logout', {}, { withToken: true })
}

/**
 * 获取公钥
 * @param mobile
 * @returns {*}
 */
 export function getPublicKey () {
  return request.get('/api/pcMember/getPublicKey')
}
