import {
  getProductFilter,
  getProductFiltertWithToken,
  getProductList,
  getProductNames,
  getFormates,
  getMaterials,
  getUnits,
  addProduct,
  getProductListWithToken,
  submitOrder,
	updateProductStatus
} from '@/api/category'
// import store from "@/store";

const category = {
  state () {
    return {
      // token: '',
      // userInfo: {},
    }
  },

  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token
    // },
  },

  actions: {
    // 获取 商品列表筛选栏
    ProductFilter ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProductFilter(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    ProductFiltertWithToken ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProductFiltertWithToken(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取 商品列表筛选栏
    ProductList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProductList(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 购买商品提交订单
    SubmitCategoryOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        submitOrder(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    ProductListWithToken ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProductListWithToken(params)
          .then((response) => {
            // commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 查询商品名称列表
    ProductNames ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProductNames(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		 // 商品 上下架
		 UpdateProductStatus ({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        updateProductStatus(id, status)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 条件查询规格列表
    Formates ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getFormates(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 条件查询材质列表
    Materials ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getMaterials(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 商品单位
    Units ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getUnits(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取 商品单位
    AddProduct ({ commit }, params) {
      return new Promise((resolve, reject) => {
        addProduct(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default category
