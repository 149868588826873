import {
  getHotProduct,
  getAvailabelProduct,
  getAdpictureList,
  getNoticeList,
  getNoticeDetails,
  getStoreDetail,
  getCategoryTree,
	getPurchaseStatistics,
	getSupplyStatistics
} from '@/api/home'
// import store from "@/store";
// import { getAvailabelProduct, getHotProduct } from "@/api/login";

const home = {
  state: {
    token: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
  },

  actions: {
    // 获取热门资源
    HotProduct () {
      return new Promise((resolve, reject) => {
        getHotProduct()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取挂牌资源
    AvailabelProduct () {
      return new Promise((resolve, reject) => {
        getAvailabelProduct()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取轮播数据
    AdpictureList () {
      return new Promise((resolve, reject) => {
        getAdpictureList()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取平台公告
    NoticeList ({ commit }, postData) {
      return new Promise((resolve, reject) => {
        getNoticeList(postData)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取平台公告 详情
    NoticeDetails ({}, id) {
      return new Promise((resolve, reject) => {
        getNoticeDetails(id)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取店铺详情
    StoreDetail ({ commit }, storeId) {
      return new Promise((resolve, reject) => {
        getStoreDetail(storeId)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		 // 获取商品分类tree
		 CategoryTree ({ commit }) {
      return new Promise((resolve, reject) => {
        getCategoryTree()
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
				})
			},
    // 获取交易专区采购统计
    PurchaseStatistics ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getPurchaseStatistics(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		 // 获取交易专区供应统计
		SupplyStatistics ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getSupplyStatistics(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
export default home
