import {
  getStoreDetailByToken,
  merchantSetMeetCode,
  pcMemberMemberCenter,
	pcMemberUpdateAddress
} from '@/api/member'
// import store from "@/store";

const member = {
  state () {
    return {
			memberCenter: {}
      // token: '',
    }
  },

  mutations: {
    SET_MEMBERCENTER: (state, memberCenter) => {
      state.memberCenter = memberCenter
    },
  },

  actions: {
    //  店铺 详情信息
    StoreDetailByToken ({ commit }) {
      return new Promise((resolve, reject) => {
        getStoreDetailByToken()
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 个人中心  店铺信息设置会面码
    MerchantSetMeetCode ({ commit }, { storeId, meetCode }) {
      return new Promise((resolve, reject) => {
        merchantSetMeetCode(storeId, meetCode)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 个人中心 会员中心数据
    PcMemberMemberCenter ({ commit }, params) {
      return new Promise((resolve, reject) => {
        pcMemberMemberCenter(params)
          .then((response) => {
            commit('SET_MEMBERCENTER', response.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
		 // 个人中心 修改企业地址
		 PcMemberUpdateAddress ({ commit }, params) {
      return new Promise((resolve, reject) => {
        pcMemberUpdateAddress(params)
          .then((response) => {
            commit('', '')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default member
