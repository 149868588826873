/* 发布需求模块 */
import { savePreOrder, getPreOrderGoodsList, getPreOrderGoodDetail, saveQuotation } from '@/api/publish'

const publish = {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        SavePreOrder ({ commit }, params) {
            return new Promise((resolve, reject) => {
                savePreOrder(params)
                .then((response) => {
                  resolve(response)
                })
                .catch((error) => {
                  reject(error)
                })
            })
        },
        GetPreOrderGoodsList ({ commit }, params) {
          return new Promise((resolve, reject) => {
              getPreOrderGoodsList(params)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
            })
          },
          GetPreOrderGoodDetail ({ commit }, params) {
            return new Promise((resolve, reject) => {
              getPreOrderGoodDetail(params)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
          })
        },
        SaveQuotation ({ commit }, params) {
          return new Promise((resolve, reject) => {
            saveQuotation(params)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
     }
}

export default publish
