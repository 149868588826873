<template>
  <div ref="target" class="xtx-infinite-loading">
    <div v-if="loading" class="loading">
      <span class="img"></span>
      <span class="text">正在加载...</span>
    </div>
    <div v-if="finished" class="none">
      <span class="img"></span>
      <span class="text">亲，没有更多了</span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

export default {
  name: 'XtxInfiniteLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    // 被监听的元素
    const target = ref(null)
    useIntersectionObserver(target, ([{ isIntersecting }]) => {
      // 判断元素是否进入可视区
      if (isIntersecting) {
        if (!props.loading && !props.finished) {
          // 触发自定义事件，告诉父组件进入了可视区
          emit('infinite')
        }
      }
    })

    return { target }
  },
}
</script>
<style scoped lang="less">
.xtx-infinite-loading {
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    .img {
      width: 50px;
      height: 50px;
      background: url(../../assets/images/load.gif) no-repeat center / contain;
    }
    .text {
      color: #999;
      font-size: 16px;
    }
  }
  .none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    .img {
      width: 200px;
      height: 134px;
      background: url(../../assets/images/none.png) no-repeat center / contain;
    }
    .text {
      color: #999;
      font-size: 16px;
    }
  }
}
</style>
