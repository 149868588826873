<template>
    <div class="app-content-container">
        <h4 class="title">
            <slot name="title"/>
        </h4>
        <div class="main-content">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageTitleTop',
}
</script>

<style lang="less" scoped>
.app-content-container {
    // padding: 20px;
.title {
    margin-bottom: 20px ;
    font-size: 22px;
    font-weight: normal;
}
}
</style>
