<template>
    <div class="no-data">
        <img :src="require('/static/icons/nodata.jpg')" alt="">
    </div>
</template>
<script>
export default {
  name: 'NoData'
}
</script>
<style lang="less">
.no-data {
    display: flex;
    justify-content: center;
    img {
        height: 100px;
        width: 100px;
    }
}
</style>
