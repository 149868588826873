/**
 * @userAPI 订单API
 */
import request from '@/utils/request'

/**
 * 创建订单
 * @returns {*}
 */
export function createOrder () {
  return request.get('/member/order/pre', {}, { withToken: true })
}

/**
 * 添加收货地址
 * @param address 收货地址信息对象
 * @returns {*}
 */
export function addAddress (address) {
  return request.post('/member/address', address, { withToken: true })
}

/**
 * 获取收货地址
 * @param address 收获地址信息
 * @returns {*}
 */
export function getAddress (address) {
  return request.get('/member/address', address, { withToken: true })
}

/**
 * 修改收货地址（根据ID）
 * @param address 收获地址信息
 * @returns {*}
 */
export function updateAddress (address) {
  return request.put(`/member/address/${address.id}`, address, {
    withToken: true,
  })
}

/**
 * 提交订单
 * @param order 订单对象
 * @returns {*}
 */
export function submitOrder (order) {
  return request.post(`/member/order`, order, {
    withToken: true,
  })
}

/**
 * 根据订单id获取订单详情
 * @param order 订单id
 * @returns {*}
 */
export function getOrderInfoById (id) {
  return request.get(`/member/order/${id}`, {}, { withToken: true })
}

/**
 * 获取 提单列表
 * @param order 订单id
 * @returns {*}
 */
export function getListPaymentOrder (params) {
  return request.post('/api/payment/listPaymentOrder', params, {
    withToken: true,
  })
}
/**
 * 取消 提单
 * @param order 订单id
 * @returns {*}
 */
export function removePaymenteOrder (params) {
  return request.post('/api/payment/cancelOrder', params, {
    withToken: true,
  })
}
/**
 * 删除 提单
 * @param order 订单id
 * @returns {*}
 */
export function delPaymenteOrder (params) {
  return request.post('/api/payment/removeOrder', params, {
    withToken: true,
  })
}
/**
 * 获取 订单列表
 * @param order 订单id
 * @returns {*}
 */
export function getListPurchaseOrder (params) {
  return request.post('/api/purchase/listPurchaseOrder', params, {
    withToken: true,
  })
}

/**
 * 获取 订单详情
 * @param order 订单id
 * @param tradeId 订单tradeId
 * @returns {*}
 */
export function getPurchasePaymentOrder (params) {
  return request.post('/api/purchase/getPurchaseOrder', params, {
    withToken: true,
  })
}
/**
 * 获取 提单详情
 * @param order 订单id
 * @param tradeId 订单tradeId
 * @returns {*}
 */
 export function getPaymentPaymentOrder (params) {
  return request.post('/api/payment/getPaymentOrder', params, {
    withToken: true,
  })
}

/**
 * 取消 订单
 * @param order 订单id
 * @param tradeId 订单tradeId * @returns {*}
 */
export function removePurchaseOrder (params) {
  return request.post('/api/purchase/cancelOrder', params, {
    withToken: true,
  })
}

/**
 * 删除 订单
 * @param order 订单id
 * @param tradeId 订单tradeId * @returns {*}
 */
 export function delPurchaseOrder (params) {
  return request.post('/api/purchase/removeOrder', params, {
    withToken: true,
  })
}
/**
 * 获取 我的报价单
 * @param order 订单id
 * @returns {*}
 */
export function getListQuotation (params) {
  return request.post('/api/quotation/listQuotation', params, {
    withToken: true,
  })
}

/**
 * 获取 我的报价单详情
 * @param order 订单id
 * @returns {*}
 */
export function getQuotationInfo (params) {
  return request.post('/api/quotation/getQuotationInfo', params, {
    withToken: true,
  })
}
/**
 * 修改 报价单 价格
 * @param order
 * @returns {*}
 */
 export function quotationUpdateQuotation (params) {
  return request.post('/api/quotation/updateQuotation', params, {
    withToken: true,
  })
}
/**
 * 取消 我的报价单
 * @param status 订单status
 * @param id id * @returns {*}
 */
 export function removeQuotationOrder (params) {
  return request.post('/api/quotation/cancelQuotation', params, {
    withToken: true,
  })
}
/**
 * 获取 我的求购单 列表
 * @param order
 * @returns {*}
 */
export function listPreOrderGoods (params) {
  return request.post('/api/purchase/listPreOrderGoods', params, {
    withToken: true,
  })
}
/**
 * 获取 我的求购单 详情
 * @param order
 * @returns {*}
 */
export function getPreOrderGoodsInfo (params) {
  return request.post('/api/purchase/getPreOrderGoodsInfo', params, {
    withToken: true,
  })
}
/**
 * 修改 修改求购商品信息
 * @param order
 * @returns {*}
 */
 export function purchaseUpdatePreOrderGoods (params) {
  return request.post('/api/purchase/updatePreOrderGoods', params, {
    withToken: true,
  })
}

/**
 * 删除 我的求购单
 * @param order
 * @returns {*}
 */
 export function delPreOrderGoods (params) {
  return request.post('/api/purchase/removePreOrderGoods', params, {
    withToken: true,
  })
}

/**
 * 取消 我的求购单
 * @param order
 * @returns {*}
 */
 export function cancelPreOrderGoods (params) {
  return request.post('/api/purchase/cancelPreOrderGoods', params, {
    withToken: true,
  })
}
/**
 * 确认 我的订单
 * @param order
 * @returns {*}
 */
 export function firmPaymentOrder (params) {
  return request.post('/api/purchase/firmPaymentOrder', params, {
    withToken: true,
  })
}
/**
 * 我的订单 上传合同
 * @param order
 * @returns {*}
 */
 export function purchaseSignContract (params) {
  return request.post('/api/purchase/signContract', params, {
    withToken: true,
  })
}
/**
 * 我的订单 上传仓单
 * @param order
 * @returns {*}
 */
 export function purchaseUploadWarehouseOrder (params) {
  return request.post('/api/purchase/uploadWarehouseOrder', params, {
    withToken: true,
  })
}
/**
 * 我的订单 上传发票
 * @param order
 * @returns {*}
 */
 export function purchaseUploadPaymentBill (params) {
  return request.post('/api/purchase/uploadPaymentBill', params, {
    withToken: true,
  })
}
/**
 * 确认 我的提单
 * @param order
 * @returns {*}
 */
 export function paymentFirmPaymentOrder (params) {
  return request.post('/api/payment/firmPaymentOrder', params, {
    withToken: true,
  })
}
/**
 * 我的提单 确认合同
 * @param order
 * @returns {*}
 */
 export function paymentConfirmContrac (params) {
  return request.post('/api/payment/confirmContract', params, {
    withToken: true,
  })
}
/**
 * 我的提单 付保证金
 * @param order
 * @returns {*}
 */
 export function paymentRequestPreOrderDepositPay (params) {
  return request.post('/api/payment/requestPreOrderDepositPay', params, {
    withToken: true,
  })
}
/**
 * 我的提单 立即支付
 * @param order
 * @returns {*}
 */
 export function paymentPayImmediately (params) {
  return request.post('/api/payment/payImmediately', params, {
    withToken: true,
  })
}
export function paymentSubmitWithGuaranteeOrder (params) {
  return request.post('/api/payment/submitWithGuaranteeOrder', params, {
    withToken: true,
  })
}
/**
 * 我的提单 确认收货
 * @param order
 * @returns {*}
 */
 export function paymentConfirmGoods (params) {
  return request.post('/api/payment/confirmGoods', params, {
    withToken: true,
  })
}
/**
 * 我的提单 支付回调
 * @param order
 * @returns {*}
 */
 export function paymentUpdatePayStatus (orderNo) {
  return request.get('/api/payment/updatePayStatus', orderNo, {
    withToken: true,
  })
}

/**
 * 我的求购单 选择该报价
 * @param order
 * @returns {*}
 */
 export function purchaseConfirmQuotation (params) {
  return request.post('/api/purchase/confirmQuotation', params, {
    withToken: true,
  })
}
