/* 资金账户模块 */
import {
  openAcount,
  getBalance,
  getDetail,
  getProvinceAndCityOptions,
  getBindBankCardList,
  getWithdrawUrl,
  getOpenAccoutBank,
  bindBankCard,
  getBankBackOrder,
} from '@/api/acount-money'

const accountMoney = {
  namespaced: true,
  state () {
    return {}
  },

  mutations: {},

  actions: {
    /* 开户 */
    OpenAcount ({ commit }, params) {
      return new Promise((resolve, reject) => {
        openAcount(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 获取余额 */
    GetBanlance ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getBalance(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 获取交易详情列表 */
    GetDetail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getDetail(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 获取省份城市 */
    GetProvinceAndCityOptions ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getProvinceAndCityOptions(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 获取银行账户列表 */
    GetBindBankCardList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getBindBankCardList(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 获取验证url */
    GetWithdrawUrl () {
      return new Promise((resolve, reject) => {
        getWithdrawUrl()
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 查询开户行 */
    GetOpenAccoutBank ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getOpenAccoutBank(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /* 银行卡绑定 */
    BindBankCard ({ commit }, params) {
      return new Promise((resolve, reject) => {
        bindBankCard(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /*  查看银行回执单地址 */
    BankBackOrder ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getBankBackOrder(params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default accountMoney
