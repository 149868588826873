/**
 * @userAPI 用户API
 */
 import request from '@/utils/request'
/**
 * 发布需求
 */
 export function savePreOrder (params) {
    return request.post('/api/purchase/savePreOrder', params, { withToken: true })
 }

/**
 * 求购列表
 */
 export function getPreOrderGoodsList (params) {
     return request.post('/api/purchase/getPreOrderGoodsList', params, { withToken: true })
 }

 /**
  * 求购详情
   */
export function getPreOrderGoodDetail (params) {
    return request.post('/api/purchase/getPreOrderGoods', params, { withToken: true })
}

 /**
  * 报价
   */
  export function saveQuotation (params) {
    return request.post('/api/quotation/saveQuotation', params, { withToken: true })
}

