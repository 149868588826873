import { createStore } from 'vuex'
// 导入 vuex 持久化存储
import createPersistedState from 'vuex-persistedstate'

import user from '@/store/user'
import cart from '@/store/cart'
// import category from '@/store/category'
import category from '@/store/modules/category'
import login from '@/store/modules/login'
import good from '@/store/modules/good'
import order from '@/store/modules/order'
import home from '@/store/modules/home'
import publish from '@/store/modules/publish'
import member from '@/store/modules/member'
import accountMoney from '@/store/modules/acount-money'
export default createStore({
  modules: {
    user,
    cart,
    category,
    login,
    home,
		good,
		order,
    publish,
		member,
    accountMoney
  },
  plugins: [
    // 配置持久化数据存储
    createPersistedState({
      key: 'ql-pc',
      paths: ['user', 'cart', 'login', 'good'],
    }),
  ],
})
