<template>
    <div class="warn-message">
      <Icon type="ios-alert" />
      <slot/>
    </div>
</template>
<script>
export default {
    name: 'PageWarnMessage'
}
</script>
<style lang="less">
.warn-message {
    background-color: #fdf6ec;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    i {
        color: #e6a23c;
    }
    color: #e6a23c;
    margin-bottom: 20px;
}
</style>
